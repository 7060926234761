@import url(https://fonts.googleapis.com/css?family=Khand:400,700);
$font-family : 'PT Sans','Helvetica Neue',Helvetica,sans-serif;
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
$yellow : #FC0;
$white : #FFF;
$offwhite : #EEE;
$gray : #CCC;
$red : #C00;
$darkred : #900;
$darkerred : #500;

/* Font Stacks */

$standardfonts : 'PT Sans','Helvetica Neue',Helvetica,sans-serif;
$titlefonts : Khand,'Helvetica Neue',sans-serif;

html, body {
/*    height: 100%;*/
}

html {
    background: $offwhite;
    // background: linear-gradient(to bottom right, $white, $gray);
    min-height:100%;
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    /*display: table;
    font-weight: 100;*/
    font-family: $standardfonts;
    transition: background 0.5s;
    background:initial;
    font-size: 1em;
}

body.active {
    background:$red;
}

a {
    color: $darkred;
}
p {
    margin: 0.5em 0;
}
.container {
    text-align: center;
    /*display: table-cell;*/
    vertical-align: middle;
}

.content {
    text-align: center;
    display: inline-block;
}


blockquote {
    margin:0;
    margin-left:1em;
    border-left:5px solid $red;
    padding-left:2em;
    color: #666;
    font-style:italic;
}
img {
width: 100%;
height: auto;
}

.container {
padding: 1em;
display: block;
vertical-align: baseline;
}

.title {
font-size: 3em;
font-size: 8vh;
}
ul {
list-style-type:none;
}


.menubar {
    //padding: 0.5em 1em;
    text-align: right;
    // background: #CCC;
}

.menubar ul li.dropdown>a, .menubar ul li.inline>a {
    color: #FFF;
}

.menubar>ul {
    padding: 0;
    margin: 0;
}
.menubar ul.menu {
    margin: 0;
    padding: 0;
}
.menubar ul.menu li {
    display: inline-block;
    margin-left: 1em;
    font-family: Helvetica;
}

.menubar ul.menu li a {
    text-decoration: none;
    font-weight: 500;
    background: $yellow;
    padding: 0.4em;
    border-radius: 5px;
}

svg#Crest {
    height: 500px;

        max-height: 40vh;
}

article img {
    max-width:100%;
    height: auto;
}

.dropdown-menu {
    right: 0;
    left: inherit;
}

li.borderbott {
    border-bottom: 1px solid #888;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

li.inline {
    display: inline-block;
    margin: 0 0.5em;
}


.heading p {
    margin: 0;
}

.heading h1 {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 900;
    font-weight: 900;
}

.topheader {
    font-family: $titlefonts;
    //margin-bottom: 1em;
    //padding-bottom: 1em;
    display: flex;
    align-items: center;
}

.tophead img {
    width: 50%;
    max-width: 350px
}

.topheader .logo img {
    width: 75%;
}

article p {
    margin-bottom: 1.5em;
}

article li {
    line-height: 1.3;
    margin-bottom: 1em;
    list-style-type: disc;
}

blockquote {
    margin-bottom: 1em;
}

a:hover {
    color: $darkerred;
}

.titleinputter {
    background: none;
    border: 0;
    font-size: 2em;
    outline: none;
    height: 100%;
    box-shadow: none;
    font-weight: 900;
}

.titleinputter:focus {
    border-bottom: 1px solid $gray;
    background: $offwhite;
    box-shadow: none;
}

#Crest path{
    transition: all 0.3s;
}
.logo svg#Crest {
    width: 100%;
    height: auto;
}

#Crest:hover path {
    fill: $yellow;
}

.loginbox {
    max-width:400px;
    width: 90%;
    margin: 0 auto;
    padding: 1em;
    box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
    background:$offwhite;
    border-radius: 0.5em;
}

/* Adminlinks*/

ul.adminlinks {
    text-align: left;
    padding: 0;
}

ul.adminlinks li {
    display: inline;
    margin-right: 1em;
    background: $yellow;
    border-radius: 5px;
    /* margin-left: -40px; */
}

ul.adminlinks li a {
    text-decoration: none;
    display:inline-block;
    padding:0.2em 0.5em;
    background:$yellow;
}

ul.adminlinks li a:hover {
    background: $red;
    color: $white;
}

.alert {
    position: fixed;
    top: 1em;
    left: 1em;
    z-index: 2;
    max-width: 80%;
}

.alert .close {
    margin-left: 1em;
}

// Tophead

.tophead {
    background: #C00;
    color: #FFF;
}

.tophead svg {
    width: 200px;
    max-width: 40%;
    /* transition: all 0.3s; */

}
.tophead svg:hover path {
    fill: #FC0;

}

.tophead svg path {
    fill: #FFF;
    transition: all 0.3s;
}

// Simple Flexbox

.flexit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

// Embed Youtube

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
