html.red {
    background: #C00;
}

html.red a {color: #FC0;}
html.red a:hover {color: #DA0;}
html.red body {
    color: #FFF;
}

html.red path {
    fill: #FFF;
}

html.red ul.dropdown-menu {
    background: #444;
}
